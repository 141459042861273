<template>
  <div class="grid" ref="grid" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: true,
    },
    auto: {
      type: Blob,
      default: true,
    },
    warp: {
      type: Blob,
      default: true,
    },
    row: { type: String },
    column: { type: String },
    height: {
      type: String,
    },
  },
  data() {
    return {
      observer: null,
      style: {
        width: "100%",
      },
    };
  },
  computed: {
    // style(){
    // }
  },
  mounted() {
    this.observer = new ResizeObserver(() => {
      this.view();
    });
    this.observer.observe(this.$refs.grid, {
      box: "border-box",
    });
  },
  beforeUnmount() {
    this.observer.unobserve(this.$refs.grid);
  },
  methods: {
    view() {
      let obj = { width: "100%", display: "grid" };

      let num = parseInt(this.$refs.grid.offsetWidth / this.width);
      if (this.column) {
        obj["grid-column-gap"] = `${this.column}px`;
        num = parseInt(
          (this.$refs.grid.offsetWidth - num * this.column) / this.width
        );
      }
      if (this.auto) {
        if (this.column) {
          obj["grid-template-columns"] = `repeat(${num}, ${
            (100 - (num * this.column) / this.$refs.grid.offsetWidth) / num
          }%)`;
        } else {
          obj["grid-template-columns"] = `repeat(${num}, ${100 / num}%)`;
        }
      } else {
        obj["grid-template-columns"] = `repeat(${num}, ${this.width}px)`;
      }
      if (this.height) {
        obj["grid-template-rows"] = `repeat(${num}, ${this.height}px)`;
      }

      if (this.row) {
        obj["grid-row-gap"] = `${this.row}px`;
      }
      if (!this.warp) {
        const length = this.$refs.grid.children.length;
        for (let n = 0; n < length; n++) {
          if (n >= num) {
            this.$refs.grid.children[n].style.display = "none";
          } else {
            this.$refs.grid.children[n].style.display = "block";
          }
        }
      }
      console.log(obj);

      this.style = obj;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>