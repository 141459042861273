<template>
  <div class="message-box">
    <el-form require-asterisk-position="right">
      <el-form-item label="最小固定宽度">
        <el-button type="primary">搜索</el-button>
        <el-button type="primary" size="small">搜索</el-button>
        <el-button>搜索</el-button>
        <el-button type="" size="small">搜索</el-button>
        <el-button type="">
          <el-icon class="el-icon--left"><Upload /></el-icon>
          私信
        </el-button>
        <el-button type="" size="small">
          <el-icon class="el-icon--left"><Upload /></el-icon>
          私信
        </el-button>
      </el-form-item>
      <el-form-item label="响应宽度">
        <el-button type="primary">发起众筹项目</el-button>
        <el-button type="primary" size="small">发起众筹项目</el-button>
        <el-button>发起众筹项目</el-button>
        <el-button type="" size="small">发起众筹项目</el-button>
        <el-button type="">
          <el-icon class="el-icon--left"><Upload /></el-icon>
          发起众筹项目
        </el-button>
        <el-button type="" size="small">
          <el-icon class="el-icon--left"><Upload /></el-icon>
          发起众筹项目
        </el-button>
        <el-button class="button-icon" icon="More" />
        <el-button class="button-icon" size="small" icon="More" />
        <el-link type="primary">primary</el-link>
        <el-link type="primary">
          <el-icon class="el-icon--left"><Upload /></el-icon>
          Check
        </el-link>
        <el-button type="" size="small" text bg>发布动态</el-button>
        <el-button type="" size="small" text bg>
          <el-icon class="el-icon--left"><Upload /></el-icon>
          发布动态
        </el-button>
        <el-button type="primary" size="small" text bg>发布动态</el-button>
        <el-button type="primary" size="small" text bg>
          <el-icon class="el-icon--left"><Upload /></el-icon>
          发布动态
        </el-button>
      </el-form-item>
      <el-form-item label="开关">
        <el-switch
          v-model="value2"
          class="ml-2"
          style="--el-switch-on-color: #00c4a1; --el-switch-off-color: #b1b1b1"
        />
        <el-switch
          v-model="value2"
          class="ml-2"
          disabled
          style="--el-switch-on-color: #00c4a1; --el-switch-off-color: #b1b1b1"
        />
      </el-form-item>
      <el-form-item label="单选">
        <el-radio-group v-model="radio1">
          <el-radio label="1" size="large">Option A</el-radio>
          <el-radio label="2" size="large" border>Option B</el-radio>
          <el-radio label="4" size="large" disabled>Option C</el-radio>
          <el-radio label="3" size="large" disabled border>Option C</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="多选">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="Option A" />
          <el-checkbox label="Option B" />
          <el-checkbox size="large" label="Option1" border />
          <el-checkbox label="Option C" />
          <el-checkbox label="disabled" disabled />
          <el-checkbox label="selected and disabled" disabled />
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="提示">
        <el-tooltip
          class="box-item"
          effect="dark"
          content="Top Center prompts info"
          placement="top"
        >
          <el-button type="primary" @click="open(1)">提示 / 窗口提示2个按钮</el-button>
        </el-tooltip>
        <el-tooltip
          class="box-item"
          effect="dark"
          content="right Center prompts info"
          placement="right"
        >
          <el-button type="primary" @click="open(2)">提示2 / 窗口提示1个按钮</el-button>
        </el-tooltip>
        <el-tooltip
          class="box-item"
          effect="dark"
          content="bottom Center prompts info"
          placement="bottom"
        >
          <el-button type="primary" @click="open(3)">提示3 / 窗口提示无按钮</el-button>
        </el-tooltip>
        <el-tooltip
          class="box-item"
          effect="dark"
          content="left Center prompts info"
          placement="left"
        >
          <el-button type="primary" @click="open(4)">提示4 / 窗口提示居中</el-button>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="toast">
        <el-button type="primary" @click="toast(1)">toast/黄色</el-button>
        <el-button type="primary" @click="toast(2)">toast/黑色</el-button>
      </el-form-item>
      <el-form-item label="抽屉">
        <el-button type="primary" @click="drawer = true">抽屉</el-button>
        <el-drawer v-model="drawer" title="标题"
        :closeOnClickModal="false">
          <span>Hi, there!</span>
          <template #footer>
            <el-button>取消</el-button>
            <el-button type="primary">确认</el-button>
          </template>
        </el-drawer>
      </el-form-item>
      <el-form-item label="输入框">
        <el-row :gutter="20" type="flex">
          <el-col :span="6">
            <el-input v-model="input1" placeholder="Pick a date" />
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="input1"
              placeholder="Pick a date"
              suffix-icon="Upload"
            />
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="input2"
              placeholder="Type something"
              prefix-icon="Upload"
            />
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="input2"
              disabled
              placeholder="Type something"
              prefix-icon="Upload"
            />
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="input1"
              maxlength="10"
              show-word-limit
              placeholder="Pick a date"
            />
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item 
        label="输入框-校验"
        prop="input6"
        style="width: 300px;"
        :rules="[
        {
          required: true,
          message: '错误提示',
          trigger: 'blur',
        }
      ]">
        <el-input
          v-model="input6"
          maxlength="10"
          placeholder="校验"
        />
      </el-form-item>
      <el-form-item label="选择器">
        <el-select v-model="value" placeholder="Select">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          />
        </el-select>
        <el-select v-model="value" disabled placeholder="Select"> </el-select>
        <el-select v-model="value" multiple placeholder="Select">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="文本域">
        <el-col :span="8">
          <el-input
            v-model="text"
            maxlength="10"
            placeholder="Please input"
            show-word-limit
            type="textarea"
          />
        </el-col>
      </el-form-item>
      <el-form-item label="计数器">
        <el-input-number
          v-model="num"
          :min="1"
          :max="10"
          @change="handleChange"
        />
        <el-input-number v-model="num" :disabled="true" />
        <el-input-number
          v-model="num"
          :min="1"
          :max="10"
          controls-position="right"
          @change="handleChange"
        />
      </el-form-item>
      <el-form-item label="上传">
        <MDOSSupload :on-success="handleUploadSuccess" :prop_obj="{
            accept: 'png',
            maxSize:10
          }"/>
        <MDOSSupload :prop_obj="{ 
          type: 2 
          }" />
      </el-form-item>
      <el-form-item label="上传">
        <MDOSSupload :prop_obj="{ type: 3 }" />
      </el-form-item>
      <el-form-item label="下拉框">
        <el-popover placement="bottom-start" :width="400" trigger="click">
          <template #reference>
            <el-button class="button-icon" icon="More" />
          </template>
          <el-table :data="gridData">
            <el-table-column width="150" property="date" label="date" />
            <el-table-column width="100" property="name" label="name" />
            <el-table-column width="300" property="address" label="address" />
          </el-table>
        </el-popover>
      </el-form-item>
      <el-form-item label="功能介绍">
        <el-tag effect="dark" color="#1A1A1A">私信</el-tag>
      </el-form-item>
      <el-form-item label="更新发布状态">
        <el-tag type="success">已定时</el-tag>
        <el-tag class="ml-2" type="info">待审核</el-tag>
        <el-tag class="ml-2" type="danger">审核拒绝</el-tag>
        <el-tag class="ml-2">默认</el-tag>
        <el-tag class="ml-2" type="warning">已失效</el-tag>
      </el-form-item>
      <el-form-item label="时间线">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in activities1"
            :key="index"
            :timestamp="activity.timestamp"
            :hollow="true"
          >
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
        <!-- 带颜色的加 type  -->
        <el-timeline class="ml-2">
          <el-timeline-item
            v-for="(activity, index) in activities1"
            :class="activity.type"
            :key="index"
            :timestamp="activity.timestamp"
            :type="activity.type"
            :hollow="true"
          >
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
        <!-- 只有一个点 -->
        <el-timeline class="ml-2">
          <el-timeline-item
            v-for="(activity, index) in activitiesOnly"
            :class="activity.type"
            :key="index"
            :timestamp="activity.timestamp"
            :type="activity.type"
            :hollow="true"
          >
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
        <!-- 两个 -->
        <el-timeline class="ml-2">
          <el-timeline-item
            v-for="(activity, index) in activitiesTwo"
            :class="activity.type"
            :key="index"
            :timestamp="activity.timestamp"
            :type="activity.type"
            :hollow="true"
          >
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
      </el-form-item>
      <el-form-item label="左对齐">
        <MDSteps :list="steps" :active="1" />
      </el-form-item>
      <el-form-item label="左对齐（带描述）">
        <MDSteps :list="steps1" :active="0" />
      </el-form-item>
      <el-form-item label="居中对齐">
        <el-steps :active="2" align-center style="width: 100%">
          <el-step
            v-for="(item, index) in steps1"
            :key="index"
            :title="item.title"
          />
        </el-steps>
      </el-form-item>
      <el-form-item label="居中对齐（带描述）">
        <el-steps :active="2" align-center style="width: 100%">
          <el-step
            v-for="(item, index) in steps1"
            :key="index"
            :title="item.title"
            :description="item.description"
          />
        </el-steps>
      </el-form-item>
      <el-form-item label="一级标签">
        <el-tabs v-model="tabActiveName">
          <el-tab-pane label="已选中 TAB" name="first">已选中 TAB</el-tab-pane>
          <el-tab-pane label="未选中 TAB 1" name="second"
            >未选中 TAB 1</el-tab-pane
          >
          <el-tab-pane label="未选中 TAB 2" name="third"
            >未选中 TAB 2</el-tab-pane
          >
          <el-tab-pane label="未选中 TAB 3" name="fourth"
            >未选中 TAB 3</el-tab-pane
          >
        </el-tabs>
      </el-form-item>
      <el-form-item label="二级标签">
        <el-tabs v-model="tabActiveName" type="card">
          <el-tab-pane label="已选中 TAB" name="first">已选中 TAB</el-tab-pane>
          <el-tab-pane label="未选中 TAB 1" name="second"
            >未选中 TAB 1</el-tab-pane
          >
          <el-tab-pane label="未选中 TAB 2" name="third"
            >未选中 TAB 2</el-tab-pane
          >
          <el-tab-pane label="未选中 TAB 3" name="fourth"
            >未选中 TAB 3</el-tab-pane
          >
        </el-tabs>
      </el-form-item>
      <el-form-item label="分页">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="1000"
        />
      </el-form-item>
      <el-form-item label="表格">
        <el-table
          ref="multipleTableRef"
          :default-sort="{ prop: 'uid', order: 'ascending' }"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column prop="uid" label="用户ID" sortable width="100" />
          <el-table-column label="用户">
            <template #default="scope">
              <div class="userInfo">
                <div class="img">
                  <CompAvtor :img_src="scope.row.icon" size="30" />
                </div>
                <div class="info">
                  <p>{{ scope.row.nickname }}</p>
                  <span v-if="scope.row.uid > 1">ID {{ scope.row.uid }}</span>
                </div>
                <el-link :underline="false">私信</el-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="项目信息">
            <template #default="scope">
              <div class="proInfo">
                <div class="cover">
                  <el-image :src="$imgUrlFilter(scope.row.cover, 64)">
                    <template #error>
                      <img
                        src="https://s.moimg.net/m/img/placeholder/headPic.png"
                      />
                    </template>
                  </el-image>
                </div>
                <div class="proRight">
                  <p class="render-line-clamp">{{ scope.row.title }}</p>
                  <span>副标题</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="输入">
            <template #default="scope">
              <el-input
                v-if="scope.row.uid == 1"
                v-model="input1"
                placeholder="请输入内容"
              />
              <el-select
                v-if="scope.row.uid == 2"
                v-model="value"
                placeholder="请选择内容"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                />
              </el-select>
              <el-input
                v-if="scope.row.uid == 3"
                v-model="input1"
                placeholder="请输入金额"
              >
                <template #append>元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template #default="scope">
              <el-tag v-if="scope.row.uid == 1" type="success">已定时</el-tag>
              <el-tag type="info">待审核</el-tag>
              <el-tag type="danger">审核拒绝</el-tag>
              <el-tag effect="normal">默认</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="标签新增/删除">
            <template #default="scope">
                <el-tag
                  v-for="tag in scope.row.tag_list"
                  :key="tag"
                  type="danger"
                  class="mx-1"
                  closable
                  :disable-transitions="false"
                  @close="handleCloseTag(tag,scope.$index)"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  v-if="scope.row.inputVisible"
                  ref="InputRef"
                  v-model="tagInputValue"
                  class="tagInput"
                  size="small"
                  @keyup.enter="handleInputConfirm(scope.$index)"
                  @blur="handleInputConfirm(scope.$index)"
                />
                <el-button v-else class="button-new-tag ml-1" size="small" @click="scope.row.inputVisible = true">
                  + 添加
                </el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-link :underline="false" @click.stop="open(scope.row.uid)"
                >操作</el-link
              >
              <el-link :underline="false">查看详情</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="上传图片（裁剪）">
        <Uploader ref="uploaderChild" :accept="'image/jpg,image/jpeg,image/png'" :isCrop="true" :minWidth="750" @onSuccess="handleSuccess" />
        <el-button @click.stop="upload">上传文件</el-button>
      </el-form-item>
      <el-form-item label="上传图片（多图）">
        <div class="mutiUploader">
          <MultiUploader :multiUploaderDrawerVisible="multiUploaderDrawerVisible" :isCrop="multiCrop" @close="multiUploaderDrawerVisible=false" @confirm="drawerConfirm" />
        </div>
        <el-button @click.stop="axuUpload(true)">多图上传（裁剪）</el-button>
        <el-button @click.stop="axuUpload(false)">多图上传（不裁剪）</el-button>
      </el-form-item>
      <el-form-item label="编辑器">
        <ContentEditor ref="editor" v-model="content" :env="env" @onInit="tinymceInit" />
      </el-form-item>
      <el-form-item label="list">
        <gridList width="500">
          <div class="grid-li" v-for="(item, idx) in options" :key="idx">
            <div></div>
            <div></div>
            <div></div>
            {{ item.value }}
          </div>
        </gridList>
      </el-form-item>
      <el-form-item label="list">
        <gridList width="500" row="20">
          <div class="grid-li" v-for="(item, idx) in options" :key="idx">
            <div></div>
            <div></div>
            <div></div>
            {{ item.value }}
          </div>
        </gridList>
      </el-form-item>
      <el-form-item label="list">
        <gridList width="500" column="20">
          <div class="grid-li" v-for="(item, idx) in options" :key="idx">
            <div></div>
            <div></div>
            <div></div>
            {{ item.value }}
          </div>
        </gridList>
      </el-form-item>
      <el-form-item label="list">
        <gridList width="500" :auto="false">
          <div class="grid-li" v-for="(item, idx) in options" :key="idx">
            <div></div>
            <div></div>
            <div></div>
            {{ item.value }}
          </div>
        </gridList>
      </el-form-item>
      <el-form-item label="list">
        <gridList width="500" :warp="false">
          <div class="grid-li" v-for="(item, idx) in options" :key="idx">
            <div></div>
            <div></div>
            <div></div>
            {{ item.value }}
          </div>
        </gridList>
      </el-form-item>
      <el-form-item label="list">
        <gridList width="500" :auto="false" :warp="false">
          <div class="grid-li" v-for="(item, idx) in options" :key="idx">
            <div></div>
            <div></div>
            <div></div>
            {{ item.value }}
          </div>
        </gridList>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import CompAvtor from "@@/compose/avtor.vue";
import gridList from "@@/common/gridList.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import MDOSSupload from "@@/common/MDOSSupload.vue";
export default {
  components: {
    CompAvtor,
    gridList,
    MDOSSupload,
  },
  computed: {},
  data() {
    return {
      input1:'',
      num: 0,
      text: "",
      value: "",
      drawer: false,
      value2: false,
      fileList: [],
      checkList: [],
      unread_number: [],
      checkedIndex: -1,
      radio1: 1,
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
          disabled: true,
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      is_read: -1,
      list: [],
      messageIndex: null,
      total: 0,
      page: 1,
      page_size: 10,
      errorShow: false,
      activities1: [
        {
          content: "支持自定义颜色",
          timestamp: "2022-01-01 20:00",
          type: "success",
        },
        {
          content: "支持自定义颜色",
          timestamp: "2022-01-01 20:00",
          type: "success",
        },
        {
          content: "支持自定义颜色",
          timestamp: "2022-01-01 20:00",
          type: "danger",
        },
        {
          content: "支持自定义颜色",
          timestamp: "2022-01-01 20:00",
        },
      ],
      activitiesOnly: [
        {
          content: "支持自定义颜色",
          timestamp: "2022-01-01 20:00",
          type: "success",
        }
      ],
      activitiesTwo: [
        {
          content: "支持自定义颜色",
          timestamp: "2022-01-01 20:00",
          type: "success",
        },
        {
          content: "支持自定义颜色",
          timestamp: "2022-01-01 20:00",
          type: "danger",
        },
      ],
      steps: [
        {
          title: "步骤正确",
        },
        {
          title: "填写完成",
        },
        {
          title: "等待填写",
        },
        {
          title: "等待填写",
        },
      ],
      steps1: [
        {
          title: "正在填写",
          description: "这是一些说明文字",
        },
        {
          title: "等待填写",
          description: "这是一些说明文字",
        },
        {
          title: "等待填写",
          description: "这是一些说明文字",
        },
        {
          title: "等待填写",
          description: "这是一些说明文字",
        },
      ],
      tabActiveName: "first",
      tableData: [
        {
          uid: 1,
          icon: "12",
          nickname: "昵称昵称",
          cover:
            "https://p6.moimg.net/path/dst_project/1003267406/202301/2917/1034/2301291034Np2QalVOYLxzr0keA6EBbW3kXK51J0.jpg",
          title: "【福兔元宵花灯】",
          tag_list:['黑名单'],
          inputVisible:false
        },
        {
          uid: 2,
          icon: "",
          nickname: "昵称昵称昵称",
          cover:
            "http://p6.moimg.net/path/dst_project/6847687/202301/2813/1252/2301281252mWqjX74b1oOE92qOLoyQrZxaGNVnD9.jpg",
          title: "冬安丨这里整夜有光，黑夜像在取暖：法米熊小夜灯",
          tag_list:[],
          inputVisible:false
        },
        {
          uid: 3,
          icon: "",
          nickname: "昵称昵称昵称",
          cover:
            "http://p6.moimg.net/path/dst_project/1003062835/202301/1617/3313/2301163313ed6jLbY10l8EQp7ngKvrxGB9PqVJRk.jpg",
          title: "棉花娃娃 |《新神榜：杨戬》官方授权！来一起RUA~",
          tag_list:[],
          inputVisible:false
        },
      ],
      tagInputValue:'',
      content: "",
      env:process.env.VUE_APP_ENV,
      multiUploaderDrawerVisible:false,
      multiCrop:false
    };
  },
  watch: {},
  mounted() {},
  methods: {
    drawerConfirm(){},
    handleSuccess(res) {
      console.log(res)
    },
    upload() {
      this.$refs.uploaderChild.onClick();
    },
    axuUpload(type){
      this.multiCrop = type;
      this.multiUploaderDrawerVisible = true;
    },
    handleClose() {},
    open(type) {
      switch (type) {
        case 1:
          ElMessageBox.confirm(
            "You have unsaved changes, save and proceed?",
            "提示",
            {
              distinguishCancelAndClose: true,
              confirmButtonText: "Save",
              cancelButtonText: "Discard Changes",
            }
          )
            .then(() => {
              ElMessage({
                type: "info",
                showClose: true,
                message: "Changes saved. Proceeding to a new route.",
              });
            })
            .catch((action) => {
              ElMessage({
                type: "warning",
                message:
                  action === "cancel"
                    ? "Changes discarded. Proceeding to a new route."
                    : "Stay in the current route",
              });
            });
          break;
        case 2:
          ElMessageBox.confirm(
            "正在抽奖，可能需要几分钟的时间，抽奖结果出炉后系统将会给您发送通知，请您注意查看。",
            "提示",
            {
              showCancelButton: false,
              confirmButtonText: "Save",
            }
          ).then(() => {
            ElMessage({
              type: "info",
              message: "Changes saved. Proceeding to a new route.",
            });
          });
          break;
        case 3:
          ElMessageBox.confirm(
            "正在抽奖，可能需要几分钟的时间，抽奖结果出炉后系统将会给您发送通知，请您注意查看。",
            "提示",
            {
              autofocus: false,
              showCancelButton: false,
              showConfirmButton: false,
            }
          ).then(() => {});
          break;
        case 4:
          ElMessageBox.confirm("「Steam兑换码已发放」开奖吗？", "提示", {
            center: true,
            showCancelButton: false,
          }).then(() => {
            ElMessage({
              type: "error",
              message: "Changes saved. Proceeding to a new route.",
            });
          });
          break;
      }
    },
    tinymceInit(editor) {
      editor.on("Change", function () {
        //监听变化
        console.log(6666);
      });
    },
    handleUploadSuccess(res, file) {
        console.log(res,file)
    },
    handleCloseTag(tag,index){
      this.tableData[index].tag_list.splice(this.tableData[index].tag_list.indexOf(tag), 1)
    },
    handleInputConfirm(index){
      if (this.tagInputValue) {
        this.tableData[index].tag_list.push(this.tagInputValue)
      }
      this.tableData[index].inputVisible = false
      this.tagInputValue = '';
    },
    toast(type) {
      if (type == 1) {
        // 警告提示 - 黄色
        ElMessage({
          type: 'warning',
          customClass: 'waring-customize',
          message: "暂无法链接到服务器，请稍后再试一试",
        });
      } else {
        // 错误提示-黑色
        ElMessage({
          type: 'info',
          customClass: 'info-customize',
          message: "内容已保存，请继续下一步吧",
        });
      }
    }
  },
};
</script>

<style lang="scss" scope>
::v-deep .grid-li {
  border: 1px solid;
}
.message-box {
  padding: 20px;
}
</style>

