<style lang="scss" scoped>
.uploader {
  .box {
    color: #7f7f7f;
    .img {
      cursor: pointer;
      position: relative;
      width: 160px;
      height: 160px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f8f8f8;

      box-sizing: border-box;
      /* 辅色/次浅灰 */
      border: 1px dashed #e8e8e8;
      .icon {
        cursor: pointer;
        display: none;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 28px;
        height: 28px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        opacity: 1;
        background: #ffffff;

        /* 辅色/浅灰 */
        border: 1px solid #b1b1b1;
      }
      img {
        width: 100%;
        height: 100%;
      }
      &:hover {
        .icon {
          display: flex;
        }
      }
      .el-icon {
        width: 16px;
        height: 16px;
        font-size: 16px;
        color: #7f7f7f;
      }
      .bttip {
        margin-top: 10px;
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
      }
    }
  }
  &.uploader2 {
    .box {
      color: #7f7f7f;
      .img {
        width: 280px;
        height: 120px;
      }
    }
  }
  .tips {
    color: #7f7f7f;
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
    letter-spacing: 0px;
    margin-top: 10px;
  }
  .file_box {
    position: relative;
    width: 400px;
    height: 40px;
    border-radius: 4px;
    opacity: 1;

    /* 自动布局 */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px 12px;
    flex-grow: 1;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    letter-spacing: 0px;

    /* 辅色/中灰 */
    color: #7f7f7f;
    background: #ffffff;
    .file_name {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      .ok {
        color: #00c4a1;
        position: absolute;
        right: 0;
      }
    }
    box-sizing: border-box;
    /* 辅色/浅灰 */
    border: 1px dashed #b1b1b1;

    &:hover {
      background: #f2f2f2;
      border: 1px dashed #1a1a1a;
      .hover {
        display: flex;
      }
    }
    .el-progress {
      position: absolute;
      left: 0;
      bottom: -6px;
      width: 100%;
      height: 2px;
      ::v-deep .el-progress-bar .el-progress-bar__outer {
        height: 2px !important;
      }
    }
    .hover {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      .icon_del {
        position: absolute;
        right: 10px;
        width: 28px;
        height: 28px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        opacity: 1;
        background: #ffffff;
      }
    }
  }
}
</style>


<template>
  <div class="common-upload">
    <input type="file" ref="filElem" :accept="accept" @change="upEditorImg($event)" style="display:none;" />
    <div :class="['uploader', `uploader${option.type}`]" @click.stop="clickInput">
      <template v-if="option.type == 3">
        <div class="file_box">
          <div v-if="!option.name" class="default">点击这里上传文件</div>
          <div v-else class="file_name">
            <el-icon><Document /></el-icon>
            {{ option.name }}
            <el-icon v-if="option.percentage == 100" class="ok"
              ><CircleCheck
            /></el-icon>
            <el-progress
              v-if="option.percentage !== 100"
              :percentage="option.percentage"
              color="#00C4A1"
              :showText="false"
            />
          </div>
          <div v-if="option.name" class="hover">
            <el-icon class="icon_del" @click.stop="del"><Delete /></el-icon>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="box">
          <div v-if="option.img_url" class="img">
            <div class="icon">
              <el-icon @click.stop="del"><Delete /></el-icon>
            </div>
            <img :src="option.img_url" alt="" />
          </div>
          <div v-else class="img">
            <el-icon><Plus /></el-icon>
            <div class="bttip">点击上传图片</div>
          </div>
        </div>
        <div class="tips">
          最多1张，尺寸 1080px*{{ option.height }}px，格式 {{option.accept}}，分辨率
          72 像素／英寸，不大于 {{option.maxSize}}MB
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { OSSUpload } from '@/utils/apiBase'
function noop() {}
export default {
  name: "common-upload",
  props: {
    prop_obj: Object,
    onSuccess: {
      type: Function,
      default: noop
    },
    onError: Function,
  },
  data() {
    return {
      option: {
        type: 1,
        width: 1080,
        height: 1080,
        maxSize:3,
        accept:'jpg、png、gif'
      },
    };
  },
  watch: {
    prop_obj: {
      handler() {
        this.init();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  computed: {
    accept(){
      return this.option.type === 3 ? 'image/jpg,image/jpeg,image/png,application/pdf' : 'image/*'
    }
  },
  methods: {
    clickInput(){
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'));
    },
    del() {
      this.option.img_url = '';
      this.option.name = '';
      this.$emit("del");
    },
    init() {
      Object.assign(this.option, this.prop_obj);
      if (this.option.type === 2) {
        this.option.width = 1080;
        this.option.height = 462;
      }
    },
    async upEditorImg(event){
      let file = event.target.files[0];
      let file_size = file.size/1024/1024;
      let isAccept = true;
      console.log(file.type)
      let imgType = await this.getImgType(file)
      console.log(imgType)
      if(file.type.split('/')[1] != imgType){
        this.$message.error('图片格式异常，报错图片后缀为:.'+file.type.split('/')[1]+' 真实格式为:.'+imgType);
        event.target.value = '';
        return false;
      }
      if(this.option.accept){
        isAccept = this.option.accept.replace(/、/g,',').split(',').includes(imgType)
        if (!isAccept) {
          this.$message.error('上传头像图片只能是 '+this.option.accept+' 格式!');
          event.target.value = '';
          return false;
        }
      }
      if (file_size > this.option.maxSize) {
          this.$message.error('文件'+file.name+'大小不能超过'+this.option.maxSize+'M');
          event.target.value = '';
          return;
      }
      this.upFile(file)
      // if(this.option.type != 3){
      //   this.getBase64(file).then(res => {
      //     if(res){
      //         this.upFile(file)
      //     }
      //   })
      // }else{
      //   this.upFile(file)
      // }
    },
    upFile(file){
      let option = {
          success: item => {
              this.option.img_url = item;
              this.option.name = file.name;
              this.option.type == 3 ? this.option.percentage = 100 : '';
              this.onSuccess(item,file);
              event.target.value = '';
          },
          error: (e,msg) => {
              this.$message.error(msg);
              event.target.value = '';
          }
      }
      let filesArr = [];
      filesArr.push(file);
      OSSUpload(option, filesArr, 0);
    },
    //限制图片尺寸
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            let imgResult = "";
            reader.readAsDataURL(file);
            reader.onload = () => {
                imgResult = reader.result;
            }
            reader.onerror = error => {
                reject(error);
            }
            reader.onloadend = () => {
                const image = new Image()
                image.onload = () => {
                    if(this.option.type == 1 && (event.target.width / event.target.height != 1 || event.target.width > 1080)) {
                        this.$message.error('图片建议尺寸1080px*1080px');
                        resolve(null)
                    }else if(this.option.type == 2 && (event.target.width > 1080 || event.target.height > 462)) {
                        this.$message.error('图片建议尺寸1080px*462px');
                        resolve(null)
                    }else {
                        resolve(imgResult)
                    }
                }
                image.src = imgResult
            }
        }).catch((e) => {
            console.log(e);
        })
    },
    //获取图片真实格式
    async getImgType(file){
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = error => {
          reject(error);
        }
        reader.onload = () => {
          const imgArrayBuffer = reader.result
          const imgUint8Array = new Uint8Array(imgArrayBuffer)
          // 各格式对应图像数据的标识数值
          const IMAGEFORMATS = [
            { ext: 'png', data: [137, 80, 78, 71, 13, 10, 26, 10] },
            { ext: 'jpeg', data: [255, 216, 255] },
            { ext: 'gif', data: [71, 73, 70] },
            { ext: 'ico', data: [0, 0, 1, 0] },
            { ext: 'bmp', data: [66, 77] },
            { ext: 'webp', data: [87, 69, 66, 80], offset: 8 }
          ]

          // 循环判断文件是否符合某个格式对应的标识数值
          for (let i = 0; i < IMAGEFORMATS.length; i++) {
            const { data, offset, ext } = IMAGEFORMATS[i]
            if (this.isEqualFormatPrefix(imgUint8Array, data, offset)) {
              resolve(ext)
            }
          }
        }
        reader.readAsArrayBuffer(file)
      }).catch((e) => {
          console.log(e);
      })
    },
    isEqualFormatPrefix(input,level,offset){
      let result = true;
      let num = offset || 0;
      for(let i = 0;i<level.length;i++){
        if(input[i+num] !== level[i]){
          result = false;
        }
      }
      return result;
    }
  },
};
</script>
